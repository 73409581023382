import React from "react";
import moment from "moment";
import { convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import * as DOMPurify from "dompurify";
import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import { showToast } from "src/app/methods/showToast";
import { momentDateFormat } from "src/app/components/CustomDatePicker/utils";
import responseCodes from "src/utils/responseCodes";
import CONSTS from "./consts";
import HtmlParser from "../components/HtmlParser/HtmlParser";

const METHODS = {
  splitNumbers: (number, separator = " ") => {
    if (number === null || number === undefined) {
      return 0;
    }
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, separator);
  },
  splitPercents: (percent, separator = " ", accuracy = 2) => {
    if (percent === null || percent === undefined) {
      return "0%";
    }
    const fixedPercent = (percent * 100).toFixed(accuracy);
    return `${fixedPercent.toString().replace(/\B(?=(\d{3})+(?!\d))/g, separator)}%`;
  },
  limitDigits: (value, digits) => {
    if (digits === 1) {
      return value.toFixed(digits).replace(/\.0$/, "");
    }
    return value.toFixed(digits);
  },
  parseHtmlFromWysiwyg: (description) => {
    return (
      <HtmlParser
        html={draftToHtml(convertToRaw(description.getCurrentContent()))}
      />
    );
  },
  getRawHtmlFromWysiwyg: (editorState) => {
    return draftToHtml(convertToRaw(editorState.getCurrentContent()));
  },
  getLowAgePickerValue: (value, lowestPossibleValue, highestPossibleValue) => {
    if (value === 0) {
      return lowestPossibleValue;
    }

    if (value > highestPossibleValue) {
      return highestPossibleValue;
    }

    return value;
  },
  getHighAgePickerValue: (value, lowestPossibleValue, highestPossibleValue) => {
    if (value === 0) {
      return highestPossibleValue;
    }

    if (value < lowestPossibleValue) {
      return lowestPossibleValue;
    }

    if (value > highestPossibleValue) {
      return highestPossibleValue;
    }

    return value;
  },
  getDate: (month = 1) => {
    const date = new Date();
    if (month !== 1) {
      return moment(date).add(1, "months").format(momentDateFormat);
    }
    return moment(date).format(momentDateFormat);
  },
  getHour() {
    const date = new Date();
    const roundedUpMinutes = this.getMinutes();
    const hour = date.getHours();

    if (roundedUpMinutes === "00") {
      return String(hour + 2).padStart(2, "0");
    }
    return String(hour + 1).padStart(2, "0");
  },
  addProtocol(url) {
    const protocol = "https://";

    if (!url) {
      return null;
    }
    if (url.includes("https://") || url.includes("http://")) {
      return url;
    }
    return protocol + url;
  },
  getMinutes: () => {
    const date = new Date();
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const roundedUpMinutes = Math.ceil(Number(minutes) / 10) * 10; // number to the next greatest multiple of 10 to fill minutes select on campaign wizard
    if (roundedUpMinutes === 0 || roundedUpMinutes === 60) {
      return "00";
    }
    return roundedUpMinutes;
  },
  getFullDate(month = 1) {
    return `${this.getDate(month)} ${this.getHour()}:${this.getMinutes()}`;
  },
  handleSelectErrorStyles: (isError) => {
    const errorBorderColor = "#ff3600";
    const regularBorderColor = "#e6e6e7";
    return {
      control: (base) => ({
        ...base,
        borderColor: isError ? errorBorderColor : regularBorderColor,
      }),
    };
  },
  clearFieldErrorStyles: (target, type) => {
    target.classList.remove(`campaign-creator__${type}--error`);
  },
  isJson: (item) => {
    item = typeof item !== "string" ? JSON.stringify(item) : item;
    try {
      item = JSON.parse(item);
    } catch (e) {
      return false;
    }

    if (typeof item === "object" && item !== null) {
      return true;
    }

    return false;
  },
  getRoundedUpMinutes: (minutes) => {
    const roundedUpMinutes = Math.ceil(Number(minutes) / 10) * 10; // number to the next greatest multiple of 10
    if (roundedUpMinutes === 0 || roundedUpMinutes === 60) {
      return "00";
    }
    return roundedUpMinutes.toString();
  },
  decodeEntities: (html) => {
    const cache = {};
    let character;
    const e = document.createElement("div");

    const clean = DOMPurify.sanitize(html);

    return clean.replace(/([&][^&; ]+[;])/g, function (entity) {
      character = cache[entity];
      if (!character) {
        e.innerHTML = entity;
        if (e.childNodes[0])
          character = cache[entity] = e.childNodes[0].nodeValue;
        else character = "";
      }
      return character;
    });
  },
  getSocialProviderIconClass: (classname, provider) => {
    const {
      instagram,
      instagramStories,
      tiktok,
      snapchat,
      facebook,
      douyin,
      youtube,
    } = CONSTS.socialProvider;

    const { instagramCarousel, cpcon, universal } =
      CONSTS.campaignWizard.socialProvider;
    switch (provider) {
      case instagram:
      case instagramCarousel:
        return `${classname}--instagram`;
      case instagramStories:
        return `${classname}--instagram-stories`;
      case tiktok:
        return `${classname}--tiktok`;
      case douyin:
        return `${classname}--douyin`;
      case snapchat:
        return `${classname}--snapchat`;
      case facebook:
        return `${classname}--facebook`;
      case cpcon:
        return `${classname}--cpcon`;
      case universal:
        return `${classname}--universal`;
      case youtube:
        return `${classname}--youtube`;
      default:
        return null;
    }
  },
  numbersWithPostfixNumbers: (number) => {
    if (isNaN(number)) return number;
    let numberRounded;
    let numberFormatted;

    if (number < 1000000) {
      if (number > 1000) {
        // Anything less than a million
        numberRounded = parseFloat(number / 1000).toFixed(1);
        numberFormatted = `${numberRounded}k`;
      } else {
        // Anything less than a thousand
        numberFormatted = number;
      }
    } else if (number < 1000000000) {
      // Anything less than a billion
      numberRounded = parseFloat(number / 1000000).toFixed(1);
      numberFormatted = `${numberRounded}M`;
    } else {
      // At least a billion
      numberRounded = parseFloat(number / 1000000000).toFixed(1);
      numberFormatted = `${numberRounded}B`;
    }
    return numberFormatted;
  },
  parseFloat2Decimal: (number) => {
    return Math.round(number * 100) / 100;
  },

  parseAudienceSearchToRequest: (searchData) => {
    const hasSocialUsername = searchData.influencerSocialUsername?.length;

    if (hasSocialUsername) {
      return {
        socialUsername: searchData.influencerSocialUsername,
        socialProvider: searchData.influencerSocialProvider?.value,
      };
    }

    let aiTags = searchData?.chosenKeywords || [];
    let hashtags = searchData.searchHashtags || [];
    let mentions = searchData.searchMentions || [];
    let bioKeywords = searchData.searchBioKeywords || [];

    let audienceInterestsMatchType = searchData.audienceInterestsOption?.value;
    let audienceBrandAffinityMatchType =
      searchData.audienceBrandAffinityOption?.value;
    let aiTagsMatchType = searchData.aiTagsOption?.value;
    let mentionsMatchType = searchData.mentionsOption?.value;
    let bioKeywordsMatchType = searchData.searchBioKeywordsOption?.value;
    let hashtagsMatchType = searchData.hashtagsOption?.value;

    if (searchData.searchContents?.length) {
      aiTags = searchData.searchContents;
      hashtags = searchData.searchContents;
      mentions = searchData.searchContents;
      bioKeywords = searchData.searchContents;

      audienceInterestsMatchType = searchData.contentsOption?.value;
      audienceBrandAffinityMatchType = searchData.contentsOption?.value;
      aiTagsMatchType = searchData.contentsOption?.value;
      mentionsMatchType = searchData.contentsOption?.value;
      bioKeywordsMatchType = searchData.contentsOption?.value;
      hashtagsMatchType = searchData.contentsOption?.value;
    }

    return {
      credibilityMin:
        searchData.audienceCredibilityScore?.value !== "any"
          ? parseInt(searchData.audienceCredibilityScore?.value)
          : null,
      paidPostPerformanceMin:
        searchData.paidPostPerformanceMin?.value !== "any"
          ? parseInt(searchData.paidPostPerformanceMin?.value)
          : null,
      erMin:
        searchData.minimumEr?.value !== "any"
          ? parseFloat(searchData.minimumEr?.value)
          : null,
      audienceCountries: searchData.selectedAudienceCountries?.length
        ? searchData.selectedAudienceCountries.map((obj) => {
            return {
              name: obj.value || obj.value,
              percent: obj.percent,
            };
          })
        : [],
      maleFollowersPercentMin:
        searchData.audienceGender?.value === "male"
          ? parseInt(searchData.audienceGenderDensity?.value)
          : null,
      femaleFollowersPercentMin:
        searchData.audienceGender?.value === "female"
          ? parseInt(searchData.audienceGenderDensity?.value)
          : null,
      audienceAgeFrom:
        searchData.audienceAgeFrom?.value !== "any"
          ? parseInt(searchData.audienceAgeFrom?.value)
          : null,
      audienceAgeTo:
        searchData.audienceAgeTo?.value !== "any"
          ? parseInt(searchData.audienceAgeTo?.value)
          : null,
      audienceAgeDensity: parseInt(searchData.audienceAgeDensity?.value),
      audienceInterests: searchData.chosenTopics?.length
        ? searchData.chosenTopics.map((item) => {
            return {
              name: item,
            };
          })
        : [],
      influencerIgCategories: searchData.chosenInfluencerIgCategories?.length
        ? searchData.chosenInfluencerIgCategories.map((item) => {
            return {
              name: item,
            };
          })
        : [],
      influencerInterests: searchData.chosenInfluencerInterests?.length
        ? searchData.chosenInfluencerInterests.map((item) => {
            return {
              name: item,
            };
          })
        : [], // to tutaj jest potrzebne do zapisu
      socialProvider: searchData.influencerSocialProvider?.value || "instagram",
      socialUsername: null,
      influencerGender:
        searchData.influencerGender?.value !== "both"
          ? searchData.influencerGender?.value
          : null,
      influencerAgeFrom:
        searchData.influencerAgeFrom?.value !== "any"
          ? parseInt(searchData.influencerAgeFrom?.value)
          : null,
      influencerAgeTo:
        searchData.influencerAgeTo?.value !== "any"
          ? parseInt(searchData.influencerAgeTo?.value)
          : null,
      influencerCountries: searchData.selectedInfluencerCountries?.length
        ? searchData.selectedInfluencerCountries.map((obj) => obj.value)
        : [],
      influencerCities: searchData.selectedInfluencerCities || [],
      influencerFollowersMin: parseInt(
        searchData.influencerFollowersFrom?.value,
      ),
      influencerFollowersMax: parseInt(searchData.influencerFollowersTo?.value),
      audienceBrandAffinity: searchData.chosenBrands || [],
      lastPostsWithin: parseInt(searchData.lastPostsWithin?.value),
      contents: searchData.searchContents || [],
      aiTags,
      hashtags,
      mentions,
      contentsMatchType: searchData.contentsOption?.value || [],
      audienceInterestsMatchType,
      hashtagsMatchType,
      audienceBrandAffinityMatchType,
      aiTagsMatchType,
      mentionsMatchType,
      bioKeywordsMatchType,
      bioKeywords,
      excludeCreatorsFromList: searchData.excludeCreatorsFromList || false,
      excludeCreatorsFromDatabase:
        searchData.excludeCreatorsFromDatabase || false,
      currentListId: searchData.currentListId || null,
    };
  },

  parseJsonToAudienceSearch: (
    searchDataJson,
    allAudienceCountries,
    allInfluencerCountries,
  ) => {
    const searchData = JSON.parse(searchDataJson);

    const {
      socialProvider,
      socialUsername,
      audienceInterests,
      influencerIgCategories,
      influencerInterests,
      credibilityMin,
      paidPostPerformanceMin,
      audienceAgeDensity,
      audienceAgeFrom,
      audienceAgeTo,
      audienceCountries,
      femaleFollowersPercentMin,
      maleFollowersPercentMin,
      influencerAgeFrom,
      influencerAgeTo,
      influencerFollowersMax,
      influencerFollowersMin,
      influencerGender,
      influencerCountries,
      influencerCities,
      audienceBrandAffinity,
      contents,
      aiTags,
      hashtags,
      mentions,
      contentsMatchType,
      hashtagsMatchType,
      audienceInterestsMatchType,
      audienceBrandAffinityMatchType,
      aiTagsMatchType,
      mentionsMatchType,
      bioKeywordsMatchType,
      erMin,
      bioKeywords,
      excludeCreatorsFromList,
      excludeCreatorsFromDatabase,
      currentListId,
      lastPostsWithin,
    } = searchData;

    return {
      chosenTopics:
        audienceInterests?.map((interest) => {
          return interest.name;
        }) || [],
      chosenInfluencerIgCategories:
        influencerIgCategories?.map((category) => {
          return category.name;
        }) || [],
      chosenInfluencerInterests:
        influencerInterests?.map((interest) => {
          return interest.name;
        }) || [], // to jest odczyt
      chosenBrands: audienceBrandAffinity,
      searchContents: contents,
      chosenKeywords: aiTags,
      searchHashtags: hashtags,
      searchMentions: mentions || [],
      contentsOption: contentsMatchType ? { value: contentsMatchType } : null,
      hashtagsOption: hashtagsMatchType ? { value: hashtagsMatchType } : null,
      mentionsOption: mentionsMatchType ? { value: mentionsMatchType } : null,
      searchBioKeywordsOption: bioKeywordsMatchType
        ? { value: bioKeywordsMatchType }
        : null,
      aiTagsOption: aiTagsMatchType ? { value: aiTagsMatchType } : null,
      audienceInterestsOption: audienceInterestsMatchType
        ? { value: audienceInterestsMatchType }
        : null,
      audienceBrandAffinityOption: audienceBrandAffinityMatchType
        ? { value: audienceBrandAffinityMatchType }
        : null,
      searchBioKeywords: bioKeywords || [],

      audienceCredibilityScore: credibilityMin
        ? {
            value: `${credibilityMin}`,
            label: `${credibilityMin} %`,
          }
        : null,
      paidPostPerformanceMin: paidPostPerformanceMin
        ? {
            value: `${paidPostPerformanceMin}`,
            label: `${paidPostPerformanceMin}%`,
          }
        : null,
      minimumEr: erMin
        ? {
            value: `${erMin}`,
            label: `≥ ${erMin}%`,
          }
        : null,
      selectedAudienceCountries:
        audienceCountries?.map((countryOjb) => {
          const foundCountry = allAudienceCountries[socialProvider].find(
            (obj) => obj.value === countryOjb.name,
          );
          return {
            value: countryOjb.name,
            label: foundCountry.label,
            percent: countryOjb.percent,
          };
        }) || [],
      audienceGender: femaleFollowersPercentMin
        ? {
            value: "female",
          }
        : maleFollowersPercentMin
          ? {
              value: "male",
            }
          : null,
      audienceGenderDensity: femaleFollowersPercentMin
        ? {
            value: femaleFollowersPercentMin,
          }
        : maleFollowersPercentMin
          ? {
              value: maleFollowersPercentMin,
            }
          : null,
      audienceAgeFrom: audienceAgeFrom
        ? {
            value: `${audienceAgeFrom}`,
            label: `${audienceAgeFrom}`,
          }
        : null,
      audienceAgeTo: audienceAgeTo
        ? {
            value: `${audienceAgeTo}`,
            label: `${audienceAgeTo}`,
          }
        : null,
      audienceAgeDensity: audienceAgeDensity
        ? {
            value: `${audienceAgeDensity}`,
            label: `${audienceAgeDensity} %`,
          }
        : null,
      influencerSocialProvider: {
        value: socialProvider,
      },
      influencerSocialUsername: socialUsername || "",
      influencerGender: influencerGender
        ? {
            value: influencerGender,
          }
        : null,
      influencerAgeFrom: influencerAgeFrom
        ? {
            value: `${influencerAgeFrom}`,
            label: `${influencerAgeFrom}`,
          }
        : null,
      influencerAgeTo: influencerAgeTo
        ? {
            value: `${influencerAgeTo}`,
            label: `${influencerAgeTo}`,
          }
        : null,
      selectedInfluencerCountries:
        influencerCountries?.map((countryCode) => {
          const foundCountry = allInfluencerCountries.find(
            (obj) => obj.value === countryCode,
          );
          return foundCountry;
        }) || [],
      selectedInfluencerCities: influencerCities,
      influencerFollowersFrom: influencerFollowersMin
        ? {
            value: `${influencerFollowersMin}`,
            label: `${influencerFollowersMin}`,
          }
        : null,

      influencerFollowersTo: influencerFollowersMax
        ? {
            value: `${influencerFollowersMax}`,
            label: `${influencerFollowersMax}`,
          }
        : null,
      excludeCreatorsFromList: excludeCreatorsFromList ?? false,
      excludeCreatorsFromDatabase: excludeCreatorsFromDatabase ?? false,
      currentListId: currentListId ?? null,
      lastPostsWithin: lastPostsWithin
        ? {
            value: lastPostsWithin,
            label: `${lastPostsWithin} month${lastPostsWithin !== 1 ? "s" : ""}`,
          }
        : null,
    };
  },

  capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  },

  limitListName: (listName) => {
    if (!listName || listName.length < 60) return listName;
    return `${listName.slice(0, 35)} ... ${listName.slice(-21)}`;
  },
  responseErrorHandler: (response) => {
    if (response.error) {
      if (
        response.error.response?.status ===
        responseCodes["402_PAYMENT_REQUIRED"]
      ) {
        return;
      }
      const { errors } = response.error.response.data.content;
      if (errors) {
        errors.forEach((error) => {
          showToast(
            "error",
            <IDHFormattedMessage id="ws_error" defaultMessage="Error" />,
            <IDHFormattedMessage id={error} />,
          );
        });
      }
    }
  },

  changeArrayElementOrder(arr, fromIndex, toIndex) {
    const element = arr[fromIndex];
    arr.splice(fromIndex, 1);
    arr.splice(toIndex, 0, element);
  },

  shortenFolderName(name, maxLength) {
    if (name.length > maxLength) {
      const result = `${name.substring(0, maxLength)}...`;
      return result;
    }
    return name;
  },
  getWorkspaceUuidFromCurrentUrl() {
    const url = window.location.pathname;
    let wsWorkspaceUuid = url.replace("/workspace/", "");

    wsWorkspaceUuid = wsWorkspaceUuid.substr(0, 36);

    const wsWorkspaceUuidFromUrl = wsWorkspaceUuid.match(
      "^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$",
    );

    if (wsWorkspaceUuidFromUrl === null) {
      return null;
    }

    return wsWorkspaceUuidFromUrl[0];
  },
};

export default METHODS;
