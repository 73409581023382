import React, { useMemo } from "react";
import "./TableSummary.scss";

import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import { ReactComponent as LinkIcon } from "src/images/share-blue.svg";
import { Img } from "src/app/components/Img/Img";
import CreatorPlaceholder from "src/images/creator-placeholder.svg";
import { getImageSource } from "src/utils/methods";
import Pictogram, {
  PictogramVariants,
} from "src/app/components/Pictogram/Pictogram";
import { useSelector } from "react-redux";
import classNames from "classnames";
import { renderGeneratorMetaValue } from "src/app/generator-data/components/ContentBox/ContentBox";
import { getStatisticHeader } from "src/helpers/statistic-list-item";
import { convertToLocalTimezone } from "src/app/methods/convertToLocalTimezone";
import { momentDateFormat } from "src/app/components/CustomDatePicker/utils";
import moment from "moment";

export function TableSummary(props) {
  const { id, boxData, height } = props;

  const creatorsList = useSelector((state) => state.reportReducer.creatorsList);
  const summaryStatistics = useSelector(
    (state) => state.reportReducer.summaryStatistics,
  );
  const publicationsSummary = useSelector(
    (state) => state.reportReducer.publicationsSummary,
  );

  const { provider, tableParts, partNumber, spaceForHeader } = boxData;

  const data = useMemo(
    () => publicationsSummary[provider],
    [publicationsSummary, provider],
  );

  const getStackedValues = (data) => {
    if (!Array.isArray(data)) return data || [];

    const newData = [[], [], [], [], []];

    summaryStatistics[provider].map((name, index) => {
      const stat = data.find((el) => el?.name === name);

      if (!stat) return;
      if (index > 4) {
        newData[index - 5].push(stat);
      } else {
        newData[index].push(stat);
      }
    });

    return newData;
  };

  const rows = useMemo(() => {
    return data.publications
      .sort((a, b) => a.creator?.rank.localeCompare(b.creator?.rank))
      .filter((item, index) => {
        if (partNumber === 1 && index < 14 - spaceForHeader) {
          return true;
        }

        if (
          partNumber === tableParts &&
          index >= (14 - spaceForHeader) * (tableParts - 1)
        ) {
          return true;
        }

        if (
          index >= (14 - spaceForHeader) * tableParts &&
          index > (14 - spaceForHeader) * (tableParts + 1)
        )
          return false;
      })
      .map((item) => {
        const { publicationDate, publicationUrl } = item;

        return {
          creator: creatorsList.find((el) => el.taskId === item.creatorUuid),
          statistics: getStackedValues(item?.statistics || []),
          publicationDate,
          publicationUrl,
        };
      });
  }, [
    creatorsList,
    data.publications,
    partNumber,
    tableParts,
    summaryStatistics,
  ]);

  const renderCreatorName = (title) => {
    if (!title) {
      return (
        <IDHFormattedMessage id="ws_not_defined" defaultMessage="Not defined" />
      );
    }

    return title;
  };

  const rowHeight = useMemo(() => {
    if (height === 1) return 48;

    return (50 * height + 20 * (height - 1)) / height;
  }, [height]);

  return (
    <div
      id={id}
      className={classNames("table-summary", {
        "table-summary--with-summary": partNumber === tableParts,
      })}
    >
      <table>
        <thead>
          <tr style={{ height: rowHeight }}>
            <th>
              <Pictogram
                identifier={`SocialProviders/${provider}`}
                variant={PictogramVariants.Original}
              />
            </th>
            <th>
              <IDHFormattedMessage id="ws_creator" defaultMessage="Creator" />
            </th>
            {rows[0]?.statistics?.map((cell) => (
              <th>
                <span className="stat-header">
                  <span>{getStatisticHeader(cell[0]?.name)}</span>
                  {cell[1] && (
                    <>
                      <span className="separator" />
                      <span>{getStatisticHeader(cell[1].name)}</span>
                    </>
                  )}
                </span>
              </th>
            ))}
            <th>
              <IDHFormattedMessage
                id="ws_publication_date"
                defaultMessage="Publication Date"
              />
            </th>
            <th>
              <IDHFormattedMessage id="ws_link" defaultMessage="Link" />
            </th>
          </tr>
        </thead>
        <tbody>
          {rows.map((item) => {
            const publicationDate = convertToLocalTimezone(
              item?.publicationDate?.date,
            );
            const formattedPublicationDate =
              moment(publicationDate).format(momentDateFormat);

            return (
              <tr style={{ height: rowHeight }}>
                <td>
                  <div className="creator-cover">
                    <Img
                      src={getImageSource(item?.creator?.cover, "small")}
                      fallbackImageSrc={CreatorPlaceholder}
                      alt="cover"
                    />
                  </div>
                </td>
                <td>{renderCreatorName(item?.creator?.title)}</td>
                {item.statistics?.map((cell) => (
                  <td>
                    <span className="stat-cell">
                      {cell.map((el) => (
                        <span>{renderGeneratorMetaValue(el) || 0}</span>
                      ))}
                    </span>
                  </td>
                ))}
                <td>
                  <span className="stat-cell">{formattedPublicationDate}</span>
                </td>
                <td>
                  <a
                    className={classNames("link", {
                      "link--disabled": !item.publicationUrl,
                    })}
                    href={item.publicationUrl}
                  >
                    <LinkIcon />
                  </a>
                </td>
              </tr>
            );
          })}

          {tableParts === partNumber && (
            <tr style={{ height: rowHeight }}>
              <td> </td>
              <td> </td>
              {getStackedValues(data.summary || [])?.map((cell) => (
                <td>
                  <span className="stat-cell">
                    {cell.map((el) => (
                      <span>{renderGeneratorMetaValue(el) || 0}</span>
                    ))}
                  </span>
                </td>
              ))}
              <td> </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
}
