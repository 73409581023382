import React from "react";
import { useDispatch, useSelector } from "react-redux";
import CustomCheckbox from "src/app/components/CustomCheckbox/CustomCheckbox";
import { getStatisticHeader } from "src/helpers/statistic-list-item";
import { setPublicationSummaryStats } from "src/redux";
import { capitalizeFirstLetter } from "src/utils/methods";

export const ToolbarListPublicationSummaryByProvider = (props) => {
  const { provider } = props;

  const {
    reportReducer: { publicationSummaryStats, publicationsSummary },
  } = useSelector((state) => state);

  const dispatch = useDispatch();

  const toggleFieldVisibility = (name, checked) => {
    let newValue = publicationSummaryStats[provider];

    if (!checked) {
      newValue = [...newValue, name];
    } else {
      newValue = newValue.filter((item) => item !== name);
    }

    dispatch(setPublicationSummaryStats(provider, newValue));
  };

  return publicationsSummary[provider].summary.map((item) => {
    const checked = publicationSummaryStats[provider].includes(item.name);

    return (
      <div className="toolbar-list__list-item">
        <CustomCheckbox
          id={`${provider}-${item.name}`}
          checked={checked}
          onChange={() => toggleFieldVisibility(item.name, checked)}
        />
        {capitalizeFirstLetter(getStatisticHeader(item.name))}
      </div>
    );
  });
};
