import React, { useEffect, useState } from "react";
import "./CreatorDiscovery.scss";

import AnimatedDiv from "src/app/components/AnimatedDiv/AnimatedDiv";
import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import { ModalTitle } from "src/app/components/Modal/Modal";
import { CustomSwitch } from "src/app/components/CustomSwitch/CustomSwitch";
import { Button } from "src/app/components/Button/Button";
import axios from "axios";
import { API_URLS } from "src/utils/API_URLS";
import { useDispatch, useSelector } from "react-redux";
import { showToast } from "src/app/methods/showToast";
import { getSettings } from "src/app/pages/CreatorDiscoveryTool/legacy/app/audiences-manager/audiencesManagerActions";
import { RootState } from "src/redux/reducers";
import { CountrySelector } from "./components/CountrySelector/CountrySelector";
import { SettingsRowElement, SettingsSeparator } from "../../components";
import { SettingsButtons } from "../../components/SettingsTabContent/SettingsTabContent";

interface Props {
  onClose: () => void;
  data: any;
  getData: () => void;
}

type EnabledProvider = Record<string, boolean>;

interface CountryData {
  enabled: boolean;
  fieldDisabled: boolean;
  label: string;
  locale: string;
  value: string;
}

type CountryBySocialProvider = Record<string, Array<CountryData>>;

export const CreatorDiscovery: React.FC<Props> = (props) => {
  const { onClose, data, getData } = props;

  const { socialProviders, countriesByMarkets } = data;

  const [creatorDiscoveryEnabled, setCreatorDiscoveryEnabled] = useState(
    data.creatorDiscoveryEnabled,
  );
  const [enabledProviders, setEnabledProviders] = useState<EnabledProvider>({});
  const [
    enableAllCountriesBySocialProvider,
    setEnableAllCountriesBySocialProvider,
  ] = useState<EnabledProvider>({});
  const [countriesBySocialProvider, setCountriesBySocialProvider] =
    useState<CountryBySocialProvider>({});

  const [brandSafetyEnabled, setBrandSafetyEnabled] = useState(
    data.brandSafetyEnabled,
  );
  const [audienceOverlapEnabled, setAudienceOverlapEnabled] = useState(
    data.audienceOverlapEnabled,
  );
  const [velocityEnabled, setVelocityEnabled] = useState(data.velocityEnabled);
  const [fullExportsEnabled, setFullExportsEnabled] = useState(
    data.fullExportsEnabled,
  );
  const [loading, setLoading] = useState(false);

  const {
    mainReducer: { activeWorkspaceUuid },
  } = useSelector((state: RootState) => state);

  const dispatch = useDispatch();

  useEffect(() => {
    const newCountriesBySocialProvider: any = {};
    const newProvidersEnabled: any = {};
    const newAllCountiresBySocialProvider: any = {};

    Object.keys(socialProviders).forEach((key: string) => {
      newCountriesBySocialProvider[key] = socialProviders[key].countries.filter(
        (item: { enabled: boolean }) => item.enabled,
      );
      newProvidersEnabled[key] = socialProviders[key].enabled;
      newAllCountiresBySocialProvider[key] =
        socialProviders[key].enableAllCountriesBySocialProvider ?? false;
    });

    if (newCountriesBySocialProvider) {
      setCountriesBySocialProvider(newCountriesBySocialProvider);
    }
    if (newProvidersEnabled) {
      setEnabledProviders(newProvidersEnabled);
    }
    if (newAllCountiresBySocialProvider) {
      setEnableAllCountriesBySocialProvider(newAllCountiresBySocialProvider);
    }
  }, [socialProviders]);

  const setSelectedCountriesByProvider = (
    provider: string,
    newValue: Array<CountryData>,
  ) => {
    setCountriesBySocialProvider((prevState: CountryBySocialProvider) => ({
      ...prevState,
      [provider]: newValue,
    }));
  };

  const setProviderEnabled = (provider: string, newValue: boolean) => {
    setEnabledProviders((prevState) => ({
      ...prevState,
      [provider]: newValue,
    }));
  };

  const setProviderAllCountriesToggle = (
    provider: string,
    newValue: boolean,
  ) => {
    setEnableAllCountriesBySocialProvider((prevState: EnabledProvider) => ({
      ...prevState,
      [provider]: newValue,
    }));
  };

  const selectCountryByMarkets = (provider: string, marketKey: any) => {
    if (!socialProviders[provider]) {
      return;
    }
    const countriesToSet = countriesByMarkets[marketKey]?.countries?.map(
      (localeItem: string) => {
        return socialProviders[provider]?.countries?.find(
          (countryItem: any) => countryItem.locale === localeItem,
        );
      },
    );

    const mergedArray = Array.from(
      new Set([
        ...(countriesBySocialProvider[provider] ?? []),
        ...countriesToSet,
      ]),
    );

    const uniqueObjects: any[] = [];
    const uniqueLocales: string[] = [];

    mergedArray?.forEach((obj) => {
      if (obj?.locale && !uniqueLocales.includes(obj.locale)) {
        uniqueLocales.push(obj.locale);
        uniqueObjects.push(obj);
      }
    });

    setSelectedCountriesByProvider(provider, uniqueObjects);
  };

  const handleConfirm = () => {
    setLoading(true);
    const url = API_URLS.setCreatorDiscoverySettings;

    const newCountriesBySocialProvider: any = {};

    Object.keys(socialProviders).forEach((key: string) => {
      newCountriesBySocialProvider[key] = countriesBySocialProvider[key].map(
        (item: { locale: string }) => item.locale,
      );
    });

    axios
      .post(url, {
        wsWorkspaceUuid: activeWorkspaceUuid,
        enabled: creatorDiscoveryEnabled,
        countriesBySocialProvider: newCountriesBySocialProvider,
        brandSafety: brandSafetyEnabled,
        audienceOverlap: audienceOverlapEnabled,
        velocity: velocityEnabled,
        enableAllCountriesBySocialProvider,
        fullExportsEnabled,
      })
      .then(() => {
        getData();
        dispatch(getSettings());
        showToast(
          "success",
          <IDHFormattedMessage id="ws_success" defaultMessage="Success" />,
          <IDHFormattedMessage
            id="ws_creator_discovery_settings_updated"
            defaultMessage="Creator discovery settings updated!"
          />,
        );
      })
      .catch(() => {
        showToast(
          "error",
          <IDHFormattedMessage id="ws_error" defaultMessage="Error" />,
          <IDHFormattedMessage
            id="ws_something_went_wrong"
            defaultMessage="Something went wrong."
          />,
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <AnimatedDiv className="creator-discovery">
        <ModalTitle>
          <IDHFormattedMessage
            id="ws_workspace_creator_discovery"
            defaultMessage="Workspace Creator Discovery"
          />
        </ModalTitle>

        <SettingsRowElement
          titleId="ws_availability"
          titleDefaultMessage="Availability"
          descId="ws_availability_settings_desc"
          descDefaultMessage="Allows enabling or disabling Creator Discovery for a specific workspace."
        >
          <CustomSwitch
            checked={creatorDiscoveryEnabled}
            onChange={() => setCreatorDiscoveryEnabled((v: boolean) => !v)}
          />
        </SettingsRowElement>

        <SettingsSeparator />

        <SettingsRowElement
          titleId="ws_platform_and_countries"
          titleDefaultMessage="Platforms and countries"
          descId="ws_platform_and_countries_settings_desc"
          descDefaultMessage="Select platforms and country databases that will be available."
        />

        <div className="creator-discovery-by-market-list">
          {Object.keys(countriesByMarkets)?.map((item: any) => {
            return (
              <button
                className="creator-discovery-by-market-item"
                onClick={() => {
                  selectCountryByMarkets("instagram", item);
                  selectCountryByMarkets("tik_tok", item);
                  selectCountryByMarkets("youtube", item);
                  selectCountryByMarkets("facebook", item);
                }}
              >
                {countriesByMarkets[item].name}
              </button>
            );
          })}

          <button
            className="creator-discovery-by-market-item__default"
            onClick={() => {
              Object.keys(socialProviders).map((key: string) =>
                setSelectedCountriesByProvider(
                  key,
                  socialProviders[key]?.countries ?? [],
                ),
              );
            }}
          >
            <IDHFormattedMessage
              id="ws_select_all"
              defaultMessage="Select all"
            />
          </button>

          <button
            className="creator-discovery-by-market-item__default"
            onClick={() => {
              Object.keys(socialProviders).map((key: string) =>
                setSelectedCountriesByProvider(key, []),
              );
            }}
          >
            <IDHFormattedMessage id="ws_clear" defaultMessage="Clear" />
          </button>
        </div>

        {Object.keys(socialProviders).map((key: string) => (
          <CountrySelector
            data={socialProviders[key]}
            selectedCountries={countriesBySocialProvider[key] || []}
            setSelectedCountries={(newValue: any) =>
              setSelectedCountriesByProvider(key, newValue)
            }
            providerEnabled={enabledProviders[key]}
            setProviderEnabled={(newValue: boolean) =>
              setProviderEnabled(key, newValue)
            }
            allCountriesEnabled={enableAllCountriesBySocialProvider[key]}
            setAllCountriesEnabled={(newValue: boolean) => {
              setProviderAllCountriesToggle(key, newValue);
            }}
          />
        ))}

        <SettingsSeparator />

        <SettingsRowElement
          titleId="ws_brand_safety"
          titleDefaultMessage="Brand Safety"
          descId="ws_brand_safety_settings_desc"
          descDefaultMessage="In semper amet elementum ac elit suspendisse faucibus. Eu magna tincidunt ipsum molestie viverra lectus interdum sagittis."
        >
          <CustomSwitch
            checked={brandSafetyEnabled}
            onChange={() => setBrandSafetyEnabled((v: boolean) => !v)}
          />
        </SettingsRowElement>

        <SettingsSeparator />

        <SettingsRowElement
          titleId="ws_audience_overlap"
          titleDefaultMessage="Audience overlap"
          descId="ws_audience_overlap_settings_desc"
          descDefaultMessage="In semper amet elementum ac elit suspendisse faucibus. Eu magna tincidunt ipsum molestie viverra lectus interdum sagittis."
        >
          <CustomSwitch
            checked={audienceOverlapEnabled}
            onChange={() => setAudienceOverlapEnabled((v: boolean) => !v)}
          />
        </SettingsRowElement>

        <SettingsSeparator />

        <SettingsRowElement
          titleId="ws_relevance_breakdown"
          titleDefaultMessage="Relevance breakdown"
          descId="ws_relevance_breakdown_settings_desc"
          descDefaultMessage="In semper amet elementum ac elit suspendisse faucibus. Eu magna tincidunt ipsum molestie viverra lectus interdum sagittis."
        >
          <CustomSwitch
            checked={velocityEnabled}
            onChange={() => setVelocityEnabled((v: boolean) => !v)}
          />
        </SettingsRowElement>

        <SettingsSeparator />

        <SettingsRowElement
          titleId="ws_allow_full_exports"
          titleDefaultMessage="Allow full exports"
          descId="ws_allow_full_exports_desc"
          descDefaultMessage="Allow members to export more than 100 influencers on Selected Creator Lists."
        >
          <CustomSwitch
            checked={fullExportsEnabled}
            onChange={() => setFullExportsEnabled((v: boolean) => !v)}
          />
        </SettingsRowElement>
      </AnimatedDiv>

      <SettingsButtons onClose={onClose}>
        <Button
          size="large"
          variant="blue"
          onClick={handleConfirm}
          disabled={loading}
        >
          <IDHFormattedMessage id="ws_update" defaultMessage="Update" />
        </Button>
      </SettingsButtons>
    </>
  );
};
