import { addHeaderToLayout } from "src/app/ReportGenerator/functions/addHeaderToLayout";
import { addSpaceIfNeeded } from "./addSpaceIfNeeded";

export const addTableSummaryToLayout = (
  newLayout,
  format,
  publicationsSummary,
) => {
  if (!publicationsSummary) return;

  Object.entries(publicationsSummary).map((item, index) => {
    const [provider, data] = item;

    const w = 12;

    const pubCount = data.publications.length;
    const spaceForHeader = index === 0 ? 1 : 0;
    const firstPartHeight = (pubCount >= 13 ? 13 : pubCount) + spaceForHeader;

    addHeaderToLayout(
      `table-summary-${provider}`,
      newLayout,
      format,
      "subheader",
      !spaceForHeader,
      w,
      firstPartHeight + 1,
    );

    const tableParts = Math.ceil((pubCount + 3) / 16);

    for (let i = 1; i <= tableParts; i++) {
      const h = getTableHeight(i, pubCount, tableParts, spaceForHeader);

      const lastEl = addSpaceIfNeeded("table-summary", newLayout, format, w, h);

      const x = 0;
      const y = lastEl.y + lastEl.h;

      newLayout.push({ i: `table-summary-${provider}-${i}`, x, y, w, h });
    }
  });
};

const getTableHeight = (i, pubCount, tableParts, spaceForHeader) => {
  if (i === 1) {
    if (tableParts === 1) {
      return pubCount + 2;
    }
    return 15 - spaceForHeader;
  }

  if (i === tableParts) {
    return pubCount + 1 + tableParts + spaceForHeader - (tableParts - 1) * 15;
  }

  return 16;
};
